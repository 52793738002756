<template>
  <div v-if="item">
    <span style="font-size: 11px" v-if="item.bookingContainer">
      <span v-if="item.bookingContainer.containerNo">
        {{ item.bookingContainer.containerNo }}
      </span>
      <span v-else>
        {{ item.bookingContainer.ctoNo }}
      </span>
    </span>
    <v-chip
      small
      v-else-if="
        item.quantityType != 'Shipment' && item.shipmentDocumentTypeContainers
      "
      >{{ item.shipmentDocumentTypeContainers.length }}</v-chip
    >
    <v-chip small outlined v-else>All</v-chip>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
  }),
  mounted() {
    this.item = this.params.data;
  },
  methods: {},
};
</script>